import React from "react";
import { Link } from "react-router-dom";
import BossbotHD from "../../assets/images/cogs/BossbotHD.png";
import Flunky from "../../assets/images/cogs/bossbots/Flunky2.png";
import PencilPusher from "../../assets/images/cogs/bossbots/Pencilpusher2.png";
import Yesman from "../../assets/images/cogs/bossbots/Yesman2.png";
import Micromanager from "../../assets/images/cogs/bossbots/Micromanager2.png";
import Downsizer from "../../assets/images/cogs/bossbots/Downsizer2.png";
import HeadHunter from "../../assets/images/cogs/bossbots/Headhunter2.png";
import CorporateRaider from "../../assets/images/cogs/bossbots/Corporate_Raider2.png";
import TheBigCheese from "../../assets/images/cogs/bossbots/Thebigcheese2.png";
import reinforced_plating from "../../assets/images/Statuseffect_reinforced.png";
import bbhq from "../../assets/images/cogs/bossbots/bbhq.PNG";
import coggolfcourse_six from "../../assets/images/cogs/bossbots/coggolfcourse_six.PNG";
import Chief_Executive_Officer from "../../assets/images/cogs/bossbots/Chief_Executive_Officer.png";
import clubhouse from "../../assets/images/cogs/bossbots/clubhouse.PNG";
import pink_slip_amt from "../../assets/images/pink_slip_amt.PNG";
import pinkslip_use from "../../assets/images/pinkslip_use.PNG";
import cog_disguise_tab from "../../assets/images/cog_disguise_tab.PNG";
import gags_tab from "../../assets/images/gags_tab.PNG";
import battle_rewards from "../../assets/images/battle_rewards.PNG";
import PanelNavigation from "../../components/PanelNavigation";

class Bossbot extends React.Component {
    render() {
        const panelNavigationLinks = [
            { href: "#list", text: "List of Bossbots" },
            { href: "#where", text: "Where to Find Bossbots" },
            { href: "#hq", text: "Bossbot HQ" },
            { href: "#suit", text: "Building a Bossbot Disguise" },
            { href: "#pinkslip", text: "Pink Slips" },
        ];

        return (
            <>
                <div className="panel">
                    <div className="panel-content">
                        <h1>Bossbots</h1>
                        <p>
                            Bossbots are one of the four tiers of cogs found in
                            Toontown. This group represents the management of
                            the cogs, being the top of the corporate ladder.
                            They are the top ranked cogs. The Bossbot
                            Headquarters is the home of the Bossbots. The boss
                            of all Bossbots is the Chief Executive Officer,
                            which toons can battle in Bossbot HQ.
                        </p>
                        <img
                            src={BossbotHD}
                            className="badge"
                            alt="Bossbot badge"
                        />
                        <p>
                            Bossbots can be identified by their cog symbol,
                            which is light-brown in color and has a striped tie
                            symbol on it (displayed to the right).
                        </p>
                        <PanelNavigation links={panelNavigationLinks} adSlot="8689384557" />
                        <span className="anchor" id="list"></span>
                        <h2>List of Bossbots</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Cog Name</th>
                                    <th>Level Range</th>
                                    <th>Headshot</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Flunky</td>
                                    <td>1 - 5</td>
                                    <td>
                                        <img
                                            src={Flunky}
                                            alt="Flunky"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pencil Pusher</td>
                                    <td>2 - 6</td>
                                    <td>
                                        <img
                                            src={PencilPusher}
                                            alt="Pencil Pusher"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Yesman</td>
                                    <td>3 - 7</td>
                                    <td>
                                        <img
                                            src={Yesman}
                                            alt="Yesman"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Micromanager</td>
                                    <td>4 - 8</td>
                                    <td>
                                        <img
                                            src={Micromanager}
                                            alt="Micromanager"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Downsizer</td>
                                    <td>5 - 9</td>
                                    <td>
                                        <img
                                            src={Downsizer}
                                            alt="Downsizer"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Head Hunter</td>
                                    <td>6 - 10</td>
                                    <td>
                                        <img
                                            src={HeadHunter}
                                            alt="Headhunter"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Corporate Raider</td>
                                    <td>7 - 11</td>
                                    <td>
                                        <img
                                            src={CorporateRaider}
                                            alt="Corporate Raider"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>The Big Cheese</td>
                                    <td>8 - 12</td>
                                    <td>
                                        <img
                                            src={TheBigCheese}
                                            alt="The Big Cheese"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h3>Version 2.0 Cogs</h3>
                        <p>
                            Version 2.0 cogs, also referred to as v2.0 cogs, are
                            cogs that have an outer suit and an inner skelecog.
                            When the outer suit is destroyed, the inner skelecog
                            will appear and continue the battle. Once the
                            skelecog is defeated, the whole v2.0 cog is
                            permanently destroyed. These types of cogs are only
                            found in Cog Golf Courses and in the C.E.O. The
                            exception is when there is a v2.0 mega invasion
                            active.
                        </p>
                        <h4>Health Points</h4>
                        <p>
                            The total health of a v2.0 cog is essentially double
                            the health of a regular cog, depending on the level.
                            For example, a level 12 cog has 196 health points. A
                            level 12 v2.0 cog will have 196 health points for
                            the outer suit and 196 health points for the inner
                            skelecog; equating to a total of 392 health points.
                            In the past, there was carry-over damage. Version
                            2.0 cogs no longer have a carry-over damage feature,
                            so use your gags wisely!
                        </p>
                        <table>
                            <tbody>
                                <tr>
                                    <th>v2.0 Cog Level</th>
                                    <th>Total HP</th>
                                </tr>
                                <tr>
                                    <td>1 v2.0</td>
                                    <td>12</td>
                                </tr>
                                <tr>
                                    <td>2 v2.0</td>
                                    <td>24</td>
                                </tr>
                                <tr>
                                    <td>3 v2.0</td>
                                    <td>40</td>
                                </tr>
                                <tr>
                                    <td>4 v2.0</td>
                                    <td>60</td>
                                </tr>
                                <tr>
                                    <td>5 v2.0</td>
                                    <td>84</td>
                                </tr>
                                <tr>
                                    <td>6 v2.0</td>
                                    <td>112</td>
                                </tr>
                                <tr>
                                    <td>7 v2.0</td>
                                    <td>144</td>
                                </tr>
                                <tr>
                                    <td>8 v2.0</td>
                                    <td>180</td>
                                </tr>
                                <tr>
                                    <td>9 v2.0</td>
                                    <td>220</td>
                                </tr>
                                <tr>
                                    <td>10 v2.0</td>
                                    <td>264</td>
                                </tr>
                                <tr>
                                    <td>11 v2.0</td>
                                    <td>312</td>
                                </tr>
                                <tr>
                                    <td>12 v2.0</td>
                                    <td>392</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4>Reinforced Plating</h4>
                        <p>
                            On Version 2.0 cogs, there is a Reinforced Plating{" "}
                            <Link to="/statuseffects">status effect</Link>{" "}
                            applied to the outer suit. Reinforced Plating
                            blocks a certain amount of damage per gag,
                            which is based on the level of the cog. The level of
                            damage blocked is determined by cog level multiplied
                            by 1.5. For example, a level 12 cogs will block 18
                            damage per gag. Reinforced Plating is removed once
                            the outer suit is destroyed and the cog becomes a
                            skelecog. Cogs with Reinforced Plating applied can
                            be identified by the green armor{" "}
                            <span>
                                <img
                                    className="book-tab"
                                    src={reinforced_plating}
                                    alt="Reinforced Plating icon"
                                />
                            </span>{" "}
                            icon above their name tags. A nice tip to know is that
                            trap gags are good way to remove this guard quickly.
                        </p>
                        <span className="anchor" id="where"></span>
                        <h2>Where to Find Bossbots</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Street</th>
                                    <th>Playground</th>
                                    <th>Percentage Found</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Punchline Place</td>
                                    <td>Toontown Central</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>Silly Street</td>
                                    <td>Toontown Central</td>
                                    <td>25%</td>
                                </tr>
                                <tr>
                                    <td>Loopy Lane</td>
                                    <td>Toontown Central</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>Barnacle Boulevard</td>
                                    <td>Donald's Dock</td>
                                    <td>90%</td>
                                </tr>
                                <tr>
                                    <td>Lighthouse Lane</td>
                                    <td>Donald's Dock</td>
                                    <td>40%</td>
                                </tr>
                                <tr>
                                    <td>Oak Street</td>
                                    <td>Daisy Gardens</td>
                                    <td>5%</td>
                                </tr>
                                <tr>
                                    <td>Maple Street</td>
                                    <td>Daisy Gardens</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>Tenor Terrace</td>
                                    <td>Minnie's Melodyland</td>
                                    <td>50%</td>
                                </tr>
                                <tr>
                                    <td>Sleet Street</td>
                                    <td>The Brrrgh</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>Walrus Way</td>
                                    <td>The Brrrgh</td>
                                    <td>90%</td>
                                </tr>
                                <tr>
                                    <td>Polar Place</td>
                                    <td>The Brrrgh</td>
                                    <td>5%</td>
                                </tr>
                                <tr>
                                    <td>Lullaby Lane</td>
                                    <td>Donald's Dreamland</td>
                                    <td>25%</td>
                                </tr>
                                <tr>
                                    <td>Pajama Place</td>
                                    <td>Donald's Dreamland</td>
                                    <td>5%</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>Bossbot Headquarters</td>
                                    <td>100%</td>
                                </tr>
                            </tbody>
                        </table>
                        <span className="anchor" id="hq"></span>
                        <h2>Bossbot HQ</h2>
                        <p>
                            Bossbot Headquarters, or often referred to as
                            Bossbot HQ, is the base of all Bossbot operations.
                            The entrance is located in{" "}
                            <Link to="/golfing">
                                Chip N' Dales MiniGolf Playground
                            </Link>
                            . You surely cannot miss the dark and gloomy tunnel
                            in the minigolf playground! When visiting this
                            headquarters, toons can access the Clubhouse and Cog
                            Golf Courses. There is the Executive Office Tower,
                            however, this is not accessible yet!
                        </p>
                        <img
                            className="content-img"
                            style={{ height: 310 + "px" }}
                            src={bbhq}
                            alt="Bossbot HQ Courtyard"
                        />
                        <span className="anchor" id="coggolf"></span>
                        <h3>Cog Golf Courses</h3>
                        <p>
                            A Cog Golf Course is a facility located in Bossbot
                            Headquarters. Toons can enter these golf course by
                            sitting on the golf karts found spread across the
                            Courtyard of the headquarters.
                        </p>
                        <img
                            className="content-img"
                            style={{ height: 260 + "px" }}
                            src={coggolfcourse_six}
                            alt="Middle six golf course entrance"
                        />
                        <p>
                            There are two different tiers of golf courses a
                            toon can enter: The First Fairway and The Final Fringe.
                            The shortest and easiest golf course is The First
                            Fairway while the longest and easiest golf
                            course is The Final Fringe. Toons can form boarding
                            groups with others toon to take on an golf course of
                            their choosing, or they can hop into a golf kart
                            with other toons!
                        </p>
                        <h4>Bossbot Golf Course Statistics</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Golf Course</th>
                                    <th>Stock Options Earned</th>
                                    <th>Gag XP Multiplier</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>The First Fairway</td>
                                    <td>906-975</td>
                                    <td>3x</td>
                                </tr>
                                <tr>
                                    <td>The Final Fringe</td>
                                    <td>2165-2305</td>
                                    <td>4x</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            The cogs in each golf course range from level 10 to
                            level 11 Version 2.0, and the{" "}
                            <Link to="undernewmanagement">Club President</Link>{" "}
                            being the boss. The cogs in a golf course are
                            Head Hunters, Corporate Raiders, and The Big
                            Cheeses. This is the only Toontown facility where
                            Version 2.0 cogs can be found. The main purpose of
                            completing Cog Golf Courses is to collect stock
                            options to earn a promotion on a Bossbot suit.
                            Click <a href="#suit">here</a> to
                            learn how to build a Bossbot suit.
                        </p>
                        <h4>Bossbot Golf Course Obstacles</h4>
                        <p>
                            Unlike the Cashbot Mints or Lawbot District Attorney
                            Offices, the Cog Golf Courses have unique room
                            plans. Throughout the course, toons will encounter a
                            select few obstacles. These obstacles must be
                            overcome to unlock a door or reach the next cog
                            battle.
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 25 + "%" }}>
                                        Obstacle
                                    </th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mole Stomp</td>
                                    <td>
                                        Toons will encounter a field with a grid
                                        of mole holes on the ground. The
                                        objective is the stomp all the red moles
                                        before time runs out. Tan moles will
                                        launch a toon in the air if touched.
                                        Upon failure, toons will lose 20 laff
                                        points and time will restart.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Hedge Mazes</td>
                                    <td>
                                        There are a few different maze patterns
                                        that will be randomly given. Toons must
                                        race through the maze for a laff bonus.
                                        If a toon fails to make their way
                                        through before the time runs out, they
                                        will lose 20 laff points and be
                                        teleported to the finish.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Cog Golf</td>
                                    <td>
                                        When reaching the golf hole, each toon
                                        will have their own board with a few
                                        rows of different colored golf balls. A
                                        random colored golf ball will be given
                                        at each shot and toons must destroy the
                                        colored golf balls by matching three or
                                        more of the corresponding color (aim and
                                        click to shoot). Upon successful
                                        completion, the toons laff meters and
                                        gags are partially refilled. Upon
                                        failure, toons will lose 20 laff points
                                        but can continue anyways.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h3>The Chief Executive Officer</h3>
                        <p>
                            The Chief Executive Officer, otherwise known as the
                            C.E.O., is the boss of the Bossbots. He lives in the
                            Bossbot Clubhouse in Bossbot HQ.
                        </p>
                        <img
                            className="content-img"
                            style={{ height: 235 + "px", borderRadius: 0 }}
                            src={Chief_Executive_Officer}
                            alt="Chief Executive Officer"
                        />
                        <p>
                            Once a toon has a Bossbot suit disguise and has
                            earned a promotion, they can fight the C.E.O. To
                            access the C.E.O. battle, a toon must enter the
                            Bossbot Clubhouse that is located in the back of the
                            headquarters. The entrances is eery and spooky in
                            appearance (shown below).
                        </p>
                        <img
                            className="content-img"
                            style={{ height: 250 + "px" }}
                            src={clubhouse}
                            alt="Bossbot HQ Clubhouse entrance"
                        />
                        <p>
                            A toon can enter this battle with a maximum of 7
                            other toons. Once all participating toons have gone
                            up the elevator, toons will find themselves in a
                            kitchen used to prepare food for the C.E.O. Toons
                            will be greeted by Good ol' Gil Giggles with two cog
                            battles on either side of the kitchen that include a
                            mix of regular and Version 2.0 cogs. Toons must
                            battle these cogs, which represent Bossbot cog
                            waiters in their white suits, until they are all
                            defeated. Proceeding these battles, toons will enter
                            the banquet room wearing new waiter cog disguises.
                            Toons must feed as many cogs as possible, with two
                            oil cans for regular cogs and three oil cans for
                            Version 2.0 cogs, in 5 minutes. Once the 5 minutes
                            is up, the C.E.O. will finally realize the cog
                            waiters are actually toons! Toons disguises will be
                            removed and the remaining cogs from the previous
                            round, if any are left, will engage in battle. Cogs
                            from the banquet room will keep the damage taken
                            from the oil cans going into battle. However, if a
                            banquet cog went angry, they reset back to full
                            health. Finally, once those remaining cogs are
                            defeated, toons will fight the C.E.O. himself by
                            harming him with seltzer water and slowing him down
                            with golf balls. Note, the C.E.O. will go through
                            phases throughout this final battle, each one beginning
                            at each change of health color. For example, the first
                            phase is at green and the last at red. As the phases
                            progress, the C.E.O. attacks become more harmful, so be
                            very careful! In the last phase, the toon-up snacks
                            will no longer come down the conveyer belt.
                            Once the C.E.O. is defeated and shamefully turned into
                            a Flunky, toons will be rewarded one or more{" "}
                            <a href="#pinkslip">pink slips</a>.
                        </p>
                        <iframe
                            className="video-frame"
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/IWBb3eqzxDo"
                            title="C.E.O. Play-through"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                        </iframe>
                        <span className="anchor" id="suit"></span>
                        <h2>Building a Bossbot Disguise</h2>
                        <p>
                            To fight the Chief Executive Officer, a toon must
                            build a Bossbot suit disguise and be ready for a
                            promotion. There are 17 total cog suit parts to
                            collect. To get all of these parts, a toon must
                            first complete building a{" "}
                            <Link to="/lawbots">Lawbot</Link> disguise. Then,
                            toons must complete toontasks given by Shep Ahoy,
                            one task for 16 cog disguise parts. The 17th part
                            will be rewarded by Flippy in Toontown Central.
                        </p>
                        <p>
                            Tasks given by Shep Ahoy will require toons to
                            defeat particular cogs. The places to find these
                            cogs are not specific.
                        </p>
                        <ol>
                            <li>Defeat a Flunky (Reward: Upper Left Leg)</li>
                            <li>
                                Defeat a Pencil Pusher (Reward: Lower Left Leg)
                            </li>
                            <li>Defeat a Yesman (Reward: Left Foot)</li>
                            <li>
                                Defeat a Micromanager (Reward: Upper Right Leg)
                            </li>
                            <li>
                                Defeat a Downsizer (Reward: Lower Right Leg)
                            </li>
                            <li>Defeat a Head Hunter (Reward: Right Foot)</li>
                            <li>
                                Defeat a Corporate Raider (Reward: Left
                                Shoulder)
                            </li>
                            <li>
                                Defeat a The Big Cheese (Reward: Right Shoulder)
                            </li>
                            <li>Defeat a Version 2.0 Cog (Reward: Chest)</li>
                            <li>
                                Defeat a Version 2.0 Cog (Reward: Health Meter)
                            </li>
                            <li>Defeat a Version 2.0 Cog (Reward: Pelvis)</li>
                            <li>
                                Defeat a Version 2.0 Cog (Reward: Upper Left
                                Arm)
                            </li>
                            <li>
                                Defeat a Version 2.0 Cog (Reward: Lower Left
                                Arm)
                            </li>
                            <li>
                                Defeat a Version 2.0 Cog (Reward: Left Hand)
                            </li>
                            <li>
                                Defeat a Version 2.0 Cog (Reward: Upper Right
                                Arm)
                            </li>
                            <li>
                                Defeat a Version 2.0 Cog (Reward: Lower Right
                                Arm)
                            </li>
                            <li>
                                Visit Flippy at Toon Hall in Toontown Central
                                (Reward: Right Hand)
                            </li>
                        </ol>
                        <p>
                            Ranking up a cog suit works similarly to how cog
                            levels work. A toon starts out as a level 1 Flunky.
                            As a toon continues to defeat the Chief Executive
                            Officer, their cog suit will continue to level up.
                            For example, to a level 2 Flunky after defeating one
                            Chief Executive Officer. Would if a toon is
                            currently a level 5 Flunky, there is no level 6
                            Flunky to promote to! Good point! A toon's cog suit
                            will then upgrade to a level 2 Pencil Pusher.
                        </p>
                        <p>
                            After defeating the Chief Executive Officer and
                            leveling up a suit, a promotion will need to be
                            earned each time. To earn a promotion, a toon needs
                            to collect a certain amount of stock options. The
                            higher level suit, the more stock options needed.
                            Stock options can be collected by defeating
                            Bossbots. Stock options will be rewarded at the end
                            of a battle in the same way gag experience is
                            earned. The most efficient way to earn stock options
                            is by completing{" "}
                            <a href="#coggolf">
                                Bossbot Golf Courses
                            </a>
                            .
                        </p>
                        <p>
                            What happens once a toon reaches a level 12 The Big
                            Cheese suit? A toon can continue to rank up their
                            suit until they reach level 50, the maxed level.
                            After advancing to level 13, a toon will have
                            teleport access to Bossbot HQ. Upon reaching level
                            50, a toon will no longer need to collect stock
                            options to fight the Chief Executive Officer. As a
                            toon reaches level 15, 20, 30, 40, and 50, they
                            receive a laff point as a reward!
                        </p>
                        <p>
                            To view cog suit information, go to the Cog Disguise
                            page by clicking the{" "}
                            <span>
                                <img
                                    className="book-tab"
                                    src={cog_disguise_tab}
                                    alt="Cog disguise book tab"
                                />
                            </span>{" "}
                            tab in the Shticker Book.
                        </p>
                        <span className="anchor" id="pinkslip"></span>
                        <h2>Pink Slips</h2>
                        <p>
                            A pink slip, also known as a "fire", is a one time
                            use reward received after successfully defeating the
                            Chief Executive Officer. Toons can use pink slips to
                            fire a chosen cog out of a cannon, shooting them out
                            of the current battle. The damage towards the cog
                            when using a pink slip is the same as their total
                            health, so once they are fired, they never come
                            back. How handy! If used against a Version 2.0 cog,
                            it will destroy both the outer suit and its inner
                            Skelecog.
                        </p>
                        <p>
                            The amount of pink slips rewarded is randomized
                            between one to five, depending on the toon tier
                            system in the battle. In other words, the amount of
                            pinks slips is determined by the average level of
                            cog disguises, where a cog level is the number of
                            promotions a toon has earned (i.e. A level 3 Yesman
                            has a value of 11). Toons can see how many pink
                            slips they have in the Inventory section{" "}
                            <span>
                                <img
                                    className="book-tab"
                                    src={gags_tab}
                                    alt="Inventory book tab"
                                />
                            </span>{" "}
                            of the Shticker Book, through the Rewards tab. The
                            pink slip tab will have a number on it, indicating
                            how many pink slips a toon has collected. A toon can
                            hold up to 200 pink slips at once.
                        </p>
                        <p>
                            To use a pink slip during any cog battle, click the
                            suitcase{" "}
                            <span>
                                <img
                                    className="book-tab"
                                    src={battle_rewards}
                                    alt="Battle suitcase to access rewards"
                                />
                            </span>{" "}
                            button to the right of the gag choice menu then
                            select the pink slip{" "}
                            <span>
                                <img
                                    className="book-tab"
                                    src={pink_slip_amt}
                                    alt="Pink slip button"
                                />
                            </span>{" "}
                            tab. Then, choose which cog to fire by selecting the
                            arrow pointing to that cog. The pink slip takes the
                            highest priority in a cog battle, meaning it
                            executes before all gag tracks and SOS calls.
                        </p>
                        <img
                            className="content-img"
                            style={{ height: 160 + "px" }}
                            src={pinkslip_use}
                            alt="Pink slip cog chooser"
                        />
                        <br />
                        <br />
                        <PanelNavigation links={panelNavigationLinks} adSlot="1733984228" />
                    </div>
                </div>
            </>
        );
    }
}

export default Bossbot;
