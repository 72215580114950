import React from "react";
import { Link } from "react-router-dom";
import rewritten_guide_banner from "../assets/images/rewritten_guide_banner.png";
import rewritten_guide_banner_cog_version from "../assets/images/rewritten_guide_banner_cog_version.png";

class Home extends React.Component {
    render() {
        return (
            <>
                <div className="logo">
                    <img
                        className="toonImg"
                        src={rewritten_guide_banner}
                        alt="Spike's Rewritten Guide"
                    />
                    <img
                        className="cogImg"
                        src={rewritten_guide_banner_cog_version}
                        alt="Spike's Rewritten Guide"
                    />
                </div>
                <div className="panel">
                    <div className="panel-content">
                        <h1>Welcome Toons!</h1>
                        <p>
                            Spike's Rewritten Guide is a one stop site with all
                            the in's and out's of Toontown Rewritten, written by
                            your very own, Spike. This guide can be used by new
                            users who need some tips for gameplay or old users
                            who may need a slight reminder of some things.
                            Updates and additions will be added as time goes on,
                            so be sure to stop by every once in a while!
                        </p>
                        <p>
                            Check out <Link to="/whatsnew">What's New</Link>{" "}
                            here at Spike's Rewritten Guide!
                        </p>
                        <hr />
                        <h2>Activities</h2>
                        <p>
                            Head over to <b>Activities</b> to gain some insight
                            on the various fun and extra activities, that is not
                            just cog fighting, you can do in Toontown to gain
                            some more laff points for your toon. Here, you can
                            also to learn about doodles and how they can be a
                            valuable aid for you and your toon peers.
                        </p>
                        <h2>Cogs</h2>
                        <p>
                            Check out the <b>Cogs</b> page to learn a lot about
                            each cog tier, their respective cog headquarters,
                            and where you can find certain cogs across Toontown.
                            Additionally, you can discover what you can do in
                            each cog headquarters and how you can be successful
                            in those types of cog related activities.
                        </p>
                        <h2>Neighborhoods</h2>
                        <p>
                            Make your way over to <b>Neighborhoods</b> to learn
                            more about what all the playgrounds across Toontown
                            have to offer! Gain more insight on the main
                            campaign, clothing shops, and much more.
                        </p>
                        <h2>Toon Tips</h2>
                        <p>
                            Navigate on over to the <b>Toon Tips</b> page to
                            access a quick reference on important tips to
                            enahnce your gameplay experience. These tips can
                            include advice about invasions, gag training,
                            fighting strategies, communication, etc.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
