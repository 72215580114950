import React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import "../scss/hamburger.scss";

class Hamburger extends React.Component {
    render() {
        return (
            <div>
                <Menu right>
                    <div>
                        <details>
                            <summary className="summary">
                                Activities&nbsp;
                                <i className="fa fa-caret-down"></i>
                            </summary>
                            <Link to="/activities">Overview</Link>
                            <Link to="/gardening">Gardening</Link>
                            <Link to="/fishing">Fishing</Link>
                            <Link to="/racing">Racing</Link>
                            <Link to="/golfing">Golfing</Link>
                            <Link
                                to="/doodles"
                                style={{ marginBottom: 20 + "px" }}
                            >
                                Doodles
                            </Link>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary className="summary">
                                Cogs&nbsp;
                                <i className="fa fa-caret-down"></i>
                            </summary>
                            <Link to="/cogs">Overview</Link>
                            <Link to="/sellbots">Sellbots</Link>
                            <Link to="/cashbots">Cashbots</Link>
                            <Link to="/lawbots">Lawbots</Link>
                            <Link
                                to="/bossbots"
                                style={{ marginBottom: 20 + "px" }}
                            >
                                Bossbots
                            </Link>
                        </details>
                    </div>
                    <Link to="/neighborhoods">Neighborhoods</Link>
                    <Link to="/toontips">Toon Tips</Link>
                    <Link to="/search">Search</Link>
                    <br /><br/>
                </Menu>
            </div>
        );
    }
}

export default Hamburger;
