import React from "react";
import { Link } from "react-router-dom";
import "../scss/about.scss";
import oreo_smiles from "../assets/images/oreo_smiles.png";

class About extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>About</h1>
                    <p>
                        Welcome to or welcome back to Spike's Rewritten
                        Guide! If you are new to this site, I am excited to
                        have you! Spike's Rewritten Guide is a one stop site
                        with all the in's and out's of Toontown Rewritten.
                        This guide is dedicated to providing useful
                        information for toons as they navigate the game.
                    </p>
                    <div className="right-align">
                        <img
                            src={oreo_smiles}
                            alt="oreo_smiles"
                        />
                    </div>
                    <p>
                        Spike's Rewritten Guide is named after toon on the
                        original Toontown game by Disney. Spike was a red
                        cat, created in the early years of Toontown, who was
                        lureless and was 129 laff. The light blue cat
                        spotted all around the website is Oreo, my toon on
                        Toontown Rewritten who is dropless and is 140 laff.
                        Oreo is named after my childhood pet, a cat who grew
                        up with me.
                    </p>
                    <h2>So, what is the point of this website?</h2>
                    <p>
                        Spike's Rewritten Guide is a great source for all
                        things Toontown Rewritten. It is here to help new
                        toons and toon veterans navigate through toontasks
                        and other activities. Not only does the site provide
                        helpful game play information, it also strives to
                        provide helpful fishing guides, gardening guides,
                        and experience-based game tips. The goal of this
                        website is to be able to answer any type of question
                        a toon might have about the game!
                    </p>
                    <p>
                        This website is always dedicated to providing
                        quality and accurate content. Please reach out via
                        the <Link to="/contact">contact form</Link> if you
                        feel there is confusing or missing content. User
                        feedback is and always will be appreciated!
                    </p>
                    <br />
                    <a
                        href="https://www.buymeacoffee.com/spikesrewrittenguide"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button><span role="img" aria-label="Pie emoji">🥧</span>{" "}
                            Buy me a gag
                        </button>
                    </a>
                </div>
            </div>
        );
    }
}

export default About;
